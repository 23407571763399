<template>
<div>
    <Breadcrumbs main="Admin" submain="Create" title="Create Call for Proposal" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter Title" v-model="details.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Link</label>
                                        <input class="form-control" type="text" placeholder="Enter Title" v-model="details.link">
                                    </div>
                                </div>
                               

                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Posted on</label>
                                        <datepicker placeholder="Select Posted on" input-class="datepicker-here form-control digits" v-model="details.posted_on"></datepicker>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select Deadline" input-class="datepicker-here form-control digits" v-model="details.deadline"></datepicker>
                                    </div>
                                </div>
                            </div>

                    
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Image</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                                            <input :class="details.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <ckeditor :editor="editor" placeholder="Enter descriptions..." v-model="details.description"></ckeditor>

                                    </div>
                                </div>
                            </div>
                           

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Create</button>
                                        <router-link to="/admin/cfp">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import treeVue from '../../advance/tree.vue';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {
            isDisabled: false,
            editor: ClassicEditor,
            details: {
                title: null,
                link: null,
                image:null,
                description:null,
                deadline:null,
                posted_on:null


            },
            show_image:null

        };
    },

    computed: {
        ...mapState({

        }),

    },

    mounted() {

    },

    methods: {

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.details.image = e.target.result;
            };
        },

        onSubmit() {
            this.isDisabled = true

            
            if (this.details.posted_on !== null) {
                this.details.posted_on = moment(String(this.details.posted_on)).format("YYYY-MM-DD");
            }
            if (this.details.deadline !== null) {
                this.details.deadline = moment(String(this.details.deadline)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("cfp/addCFP", {
                cfp: this.details,
            }).then(response => {
                if (response.status == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isDisabled = false,
                        this.details.title = null,
                        this.details.link = null,
                        this.details.image = null,
                        this.details.description = null,
                        this.details.deadline = null,
                        this.details.posted_on = null,
                       

                        setTimeout(() => {
                            this.$router.push('/admin/cfp')
                        }, 2000)

                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        }
    }
};
</script>
