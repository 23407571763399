import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'GALLERY_UPDATE',
    smsDelete : 'GALLERY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        job:[],
        job_detail:{},
       
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        jobLoading: false,
        jobError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_JOB(state, law) {
            state.job = law;
        },
        SET_JOB_DETAIL(state, detail) {
            state.job_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        JOB_LOADING (state, loading) {
            state.jobLoading = loading;
        },

        JOB_ERROR (state, hasError) {
            state.jobError = hasError;
        },
        


       
    },

    actions: {
        //Team Member
        async addJob ({commit, dispatch}, payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.job_create, payload.job, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
               
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        async fetchJobDetail ({ commit },payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.job_details + payload, config);
                let response = result.data.data

                commit('SET_JOB_DETAIL', response);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },

       


        async fetchJobList ({ commit }) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.job_list, config);
                let response = result.data

                commit('SET_JOB', response.data);

            } catch(error) {
                commit('JOB_ERROR', true);
            }
            finally {
                commit('JOB_LOADING', false);
            }
        },


    
        async updateJob ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.job_edit+payload.job_id, payload.job, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('JOB_ERROR', true);
            }finally {
                commit('JOB_LOADING', false);
            }
        },

        
        async deleteJob({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
          
            let response = await axios.delete(V1API.job_delete+payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchJobList')
            
            return response
        },


        async updateStatusToggle({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            console.log(payload.delete)
            let response = await axios.get(V1API.job_status_toggle+payload.job_id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchJobList')
            
            return response
        },


        











       








    },

    getters: {}
}
