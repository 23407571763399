<template>
    <div>
        <Breadcrumbs main="Admin" title="Fellowship" />
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header header-mobile">
                            <router-link to="/admin/fellowship-create">
                                <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                                    <i class="fa fa-light fa-plus"></i>
                                    <h7>Add New Fellowship</h7>
                                </button>
                            </router-link>
                        </div>
    
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
                                    <div class="d-flex flex-column flex-xl-row " style="gap: 10px;">
                                        <b-input-group class="datatable-btn">
                                            <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
    
                                    </div>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div v-if="!loading && !error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="items" :fields="tablefields" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
    
                                        <template #cell(is_active)="row">
                                            
                                                <span v-if="row.value" @click="updatefellowshipStatus(row.item.id)" style="cursor: pointer;" v-b-tooltip.hover title="Toggle Status">
                                                    <b-badge variant="success">Active</b-badge>
                                                </span>
                                                <span v-else @click="updatefellowshipStatus(row.item.id)"  style="cursor: pointer;" v-b-tooltip.hover title="Toggle Status">
                                                    <b-badge variant="danger">Inactive</b-badge>
                                                </span>
                                          
                                        </template>
    
                                        <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deletefellowship(data.item.id)" title="Delete"></feather>
                                                <router-link :to="`/admin/fellowship-edit/${data.item.id}`">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit"></feather>
                                                </router-link>
                                            </span>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
    
                            <div v-else>
                                <loader />
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    
        <!-- Delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this Fellowship?</div>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import js from '../../../auth/js';
    import {
        myHost
    } from '../../../_helpers/host'
    import loader from '../loader/loader.vue'
    export default {
        components: {
            loader,
        },
        data() {
            return {
                host: myHost.host,
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                  
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true
                    },
                    {
                        key: 'available_position',
                        label: 'Available Position',
                        sortable: true
                    },
                    {
                        key: 'deadline',
                        label: 'Deadline',
                        sortable: true
                    },
                    {
                        key: 'is_active',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        sortable: false
                    }
                ],
    
                filter: null,
                delete_value: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
            };
        },
    
        computed: {
            ...mapState({
                items: state => state.fellowship.fellowship,
                loading: state => state.fellowship.fellowshipLoading,
                error: state => state.fellowship.fellowshipError,
            }),
    
            sortOptions() {
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
        },
    
        mounted() {
    
            this.$store.dispatch("fellowship/fetchFellowshipList").then(() => {
                this.totalRows = this.items;
    
            });
    
        },
    
        watch: {
    
        },
    
        methods: {
    
            truncateText(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                } else {
                    return text;
                }
            },
    
            resetModal() {
                this.selectedStatus = ''
            },
    
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            deletefellowship(id) {
                this.delete_value = id
            },
    
            handleDeleteOk() {
                this.$store.dispatch("fellowship/deleteFellowship", {
                    delete: this.delete_value,
                }).then(response => {
                    if (response.status == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },

            updatefellowshipStatus(id){
                this.$store.dispatch("fellowship/updateStatusToggle", {
                    fellowship_id: id,
                }).then(response => {
                    if (response.status == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
    
        }
    };
    </script>
    
    <style scoped>
    .header-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    @media screen and (max-width: 460px) {
        .header-mobile {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: start;
        }
    }
    </style>
    