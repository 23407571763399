<template>
<div>
    <Breadcrumbs main="Admin" submain="Edit" title="Job Edit" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <px-card v-if="!loading && !error">
                    <div slot="with-padding">
                        <div class="form theme-form">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Job Title</label>
                                        <input class="form-control" type="text" placeholder="Enter Job Title" v-model="details.title">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Deadline</label>
                                        <datepicker placeholder="Select Deadline" input-class="datepicker-here form-control digits" v-model="details.deadline"></datepicker>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Available Positions</label>
                                        <input class="form-control" type="number" min="0" placeholder="Enter Position" v-model="details.available_position">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Salary</label>
                                        <input class="form-control" type="text" placeholder="Enter Salary" v-model="details.job_description.salary">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Job Location</label>
                                        <input class="form-control" type="text" placeholder="Enter Job Location" v-model="details.job_description.job_location">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Link</label>
                                        <input class="form-control" type="text" placeholder="Enter Job Link" v-model="details.link">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Logo</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                                            <input :class="details.job_description.logo ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Job Description</label>
                                        <ckeditor :editor="editor" placeholder="Enter job descriptions..." v-model="details.job_description.description"></ckeditor>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Qualifications</label>
                                        <ckeditor :editor="editor" placeholder="Enter job qualifications..." v-model="details.qualification.qualifications"></ckeditor>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button @click="onSubmit" class="btn btn-success mr-3" :disabled="isDisabled">Update</button>
                                        <router-link to="/admin/jobs">
                                            <button class="btn btn-danger" href="#">Go Back</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </px-card>
                <px-card v-else>
                    <div slot="with-padding">
                        <loader />
                    </div>
                </px-card>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'sweetalert2/src/sweetalert2.scss';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import treeVue from '../../advance/tree.vue';
import loader from '../loader/loader.vue'
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect,
        loader
    },

    data() {
        return {
            isDisabled: false,
            editor: ClassicEditor,
            details: {
                title: null,
                available_position: null,
                qualification: {
                    qualifications: null,
                },
                job_description: {
                    description: null,
                    salary: null,
                    job_location: null,
                    logo:null,
                },
                deadline: null,
                link: null

            },
            show_image:null,

        };
    },

    computed: {
        ...mapState({
            jobDetails: state => state.job.job_detail,
            loading: state => state.job.jobLoading,
            error: state => state.job.jobError,
        }),

    },

    mounted() {
        this.$store.dispatch("job/fetchJobDetail", this.$route.params.id)
    },

    watch: {
        jobDetails(oldValue, newValue) {
            this.details.title = this.jobDetails.title ? this.jobDetails.title : null
            this.details.available_position = this.jobDetails.available_position ? this.jobDetails.available_position : null
            this.details.qualification.qualifications = this.jobDetails.qualification.qualifications ? this.jobDetails.qualification.qualifications : null
            this.details.job_description.description = this.jobDetails.job_description.description ? this.jobDetails.job_description.description : null
            this.details.job_description.salary = this.jobDetails.job_description.salary ? this.jobDetails.job_description.salary : null
            this.details.job_description.job_location = this.jobDetails.job_description.job_location ? this.jobDetails.job_description.job_location : null
            this.details.deadline = this.jobDetails.deadline ? this.jobDetails.deadline : null
            this.details.link = this.jobDetails.link ? this.jobDetails.link : null
            this.show_image = this.jobDetails.job_description.logo ? this.jobDetails.job_description.logo: null

        },
    },

    methods: {

        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.details.job_description.logo = e.target.result;
            };
        },

        onSubmit() {
            this.isDisabled = true

            if (this.details.deadline !== null) {
                this.details.deadline = moment(String(this.details.deadline)).format("YYYY-MM-DD");
            }

            this.$store.dispatch("job/updateJob", {
                job: this.details,
                job_id: this.$route.params.id
            }).then(response => {
                if (response.status == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });
                    this.isDisabled = false,
                        this.details.title = null,
                        this.details.available_position = null,
                        this.details.qualification.qualifications = null,
                        this.details.job_description.description = null,
                        this.details.job_description.salary = null,
                        this.details.job_description.job_location = null,
                        this.details.deadline = null,
                        this.details.link = null,

                        setTimeout(() => {
                            this.$router.push('/admin/jobs')
                        }, 2000)

                } else {
                    this.isDisabled = false
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }

            })
        }
    }
};
</script>
