<template>
<div>
    <Breadcrumbs main="Admin" submain="Home" title="Slider" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-header">
                        <button class="btn btn-primary" type="submit" v-b-modal.modal-prevent-closing style="display: flex; gap: 12px; align-items: center;">
                            <i class="fa fa-light fa-plus"></i>
                            <h7>Add New Slider</h7>
                        </button>
                    </div>

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="slider_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(image)="row">
                                        <img :src="host+row.item.image" alt="" style="width: 120px; height: 80px;">
                                    </template>

                                    <template #cell(title)="row">
                                       <div v-if="row.item.title">
                                            <p>{{ row.item.title }}</p>
                                       </div>
                                    </template>
                                    <template #cell(link)="row">
                                       <div v-if="row.item.link">
                                            <a :href="row.item.link" target="_blank" class="link-style">{{ row.item.link.substring(0,40) }}</a>
                                       </div>
                                       <div v-else>
                                            N/A
                                       </div>
                                    </template>
                                    <template #cell(short_title)="row">
                                       <div v-if="row.item.short_title">
                                            <p>{{ row.item.short_title }}</p>
                                       </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">

                                            <div>
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteSlider(data.item.id)" title="Delete"></feather>
                                            </div>

                                            <div @click="editSlider(data.item)">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover title="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                            </div>
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>

                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Gallery Create Modal -->
    <b-modal id="modal-prevent-closing" ref="modal" title="Add Slider" @show="resetModal" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit()">

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Short Title</label>
                        <input class="form-control" type="text" placeholder="Enter short title" v-model="slider.short_title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter slider title" v-model="slider.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Link</label>
                        <input class="form-control" type="text" placeholder="Enter link URL" v-model="slider.link">
                    </div>
                </div>
            </div>
           
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image *</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="slider.image ? 'ml-4' : 'ml-0'" type="file" accept="image/*" @change="uploadFile">
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>

    <!-- Slider Edit Modal -->
    <b-modal id="modal-prevent-closing-edit" ref="modal" title="Edit Slider" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
        <form ref="form" @submit.stop.prevent="handleEditSubmit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Short Title</label>
                        <input class="form-control" type="text" placeholder="Enter short title" v-model="edit_slider.short_title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input class="form-control" type="text" placeholder="Enter title" v-model="edit_slider.title">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Link</label>
                        <input class="form-control" type="text" placeholder="Enter link URL" v-model="edit_slider.link">
                    </div>
                </div>
            </div>
          
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Image</label>
                        <div class="flex items-center">
                            <img v-if="show_image" style="width: 120px; height: 80px;" :src="show_image">
                            <input :class="edit_slider.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="EdituploadFile">
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </b-modal>
    <!-- Gallery Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this slider?</div>
    </b-modal>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import moment from "moment";
import {
    mapState
} from 'vuex';
import {
    myHost
} from '../../../_helpers/host'
import js from '../../../auth/js';
import loader from '../loader/loader.vue'
export default {
    components: {
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'short_title',
                    label: 'Short Title',
                    sortable: true
                },
                
           
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'link',
                    label: 'Link',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],
           

            slider: {
                title: '',
                short_title:'',
                image: '',
                link:'',

            },

            edit_slider: {
                id: '',
                short_title:'',
                title: '',
                image: '',
                link:'',

            },
            show_image: null,

            delete_value: null,

            filter: null,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        
        };
    },

    computed: {
        ...mapState({
            slider_list: state => state.slider.slider,
            loading: state => state.slider.sliderLoading,
            error: state => state.slider.sliderError,

        }),

        sortOptions() {
            // Create an options list from our fields
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
            });
        },

    },

    mounted() {
        this.$store.dispatch("slider/fetchSlider").then(() => {
            this.items = this.slider_list
            this.totalRows = this.items.length;
        });

    },

    methods: {
        uploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.slider.image = e.target.result;

            };
        },
        EdituploadFile(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.show_image = e.target.result
                this.edit_slider.image = e.target.result;
            };
        },
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        resetModal() {
            this.slider.title = null
            this.slider.short_title = null
            this.slider.image = null
            this.slider.link = null
            this.show_image = null
        
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()

        },

        handleSubmit() {
            if(this.slider.image == null){
                return
            }

            this.$store.dispatch("slider/addSlider", {
                slider: this.slider
            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.slider.title = null,
                    this.slider.image = null,
                    this.show_image = null,
                    this.slider.link = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

        editSlider(detail) {

            this.edit_slider.id = detail.id ? detail.id : null
            this.edit_slider.short_title = detail.short_title ? detail.short_title : null
            this.edit_slider.title = detail.title ? detail.title : null
            this.edit_slider.link = detail.link ? detail.link : null
            this.show_image = detail.image ? this.host + detail.image : null

        },
        resetEditModal() {
            this.edit_slider.title = null
            this.edit_slider.short_title = null
            this.edit_slider.image = null,
            this.edit_slider.link = null
            this.show_image = null

        },

        handleEditOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleEditSubmit()
        },

        handleEditSubmit() {

            this.$store.dispatch("slider/updateSlider", {
                id: this.edit_slider.id,
                slider: this.edit_slider,

            }).then(response => {

                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.edit_slider.title = null
                    this.edit_slider.image = null
                    this.edit_slider.link = null
                    this.show_image = null
                

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })

            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-edit')
            })
        },
        deleteSlider(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("slider/deleteSlider", {
                delete: this.delete_value
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>
