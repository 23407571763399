<template>
    <div>
        <Breadcrumbs main="Admin"  title="Where We Work" />
   
        <div class="container-fluid">
            <div class="row">
                <div style="width: 100%;">
                    <div class="main-div-layout">
    
                        <div class="col-xl-7">
    
                            <div class="card">
                                <div class="card-body" v-if="test">
                                    <div v-for="(fo, index) in layouts" :key="index">
                                        <div>
                                            <div style="display: flex; gap: 4px; margin:12px 0px;">
                                                <div class="big-screen-width">
                                                    <div>
                                                        <b-form-select v-model="fo.template">
                                                            <b-form-select-option v-for="option in options" :value="option.value">{{ option.title }}</b-form-select-option>
                                                        </b-form-select>
                                                    </div>
                                                </div>
    
                                                <div style="width: 20%; display: flex; align-items: center;">
                                                    
                                                    <svg @click="addField(input, layouts)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path fill="#00477a" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                    </svg>
    
                                                  
                                                    <svg v-show="layouts.length > 1" @click="removeField(index, layouts)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                        <path fill="#b91c1c" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                    </svg>
                                                </div>
                                            </div>
    
                                            <div style="display: flex; flex-direction: column; gap: 12px;" class="section-screen" v-if="fo.template == 1">
                                                <div class="w-fullbg-white rounded-sm">
                                                    <div style="display: flex; gap: 16px; align-items: center;">
                                                        <img v-if="fo.image" style="height: 160px; max-width: 75%;" :src="fo.image" alt="">
                                                        <b-button style="height: 40px;" v-b-modal.bv-modal-example-xl @click="getImage(fo)">Select Image</b-button>
                                                    </div>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.caption" type="text" placeholder="Enter image caption"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.title" type="text" placeholder="Enter title"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-textarea v-model="fo.details" id="textarea-default" placeholder="Enter details"></b-form-textarea>
                                                 
                                                </div>
    
                                            </div>
    
                                            <div style="display: flex; flex-direction: column; gap: 12px;" class="section-screen" v-if="fo.template == 2">
                                                <div class="w-fullbg-white rounded-sm">
                                                    <div style="display: flex; gap: 16px; align-items: center;">
                                                        <img v-if="fo.image" style="height: 160px; max-width: 75%;" :src="fo.image" alt="">
                                                        <b-button style="height: 40px;" v-b-modal.bv-modal-example-xl @click="getImage(fo)">Select Image</b-button>
                                                    </div>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.caption" type="text" placeholder="Enter image caption"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.title" type="text" placeholder="Enter title"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-textarea v-model="fo.details" id="textarea-default" placeholder="Enter details"></b-form-textarea>
                                                </div>
    
                                            </div>
                                            <div style="display: flex; flex-direction: column; gap: 12px;" class="section-screen" v-if="fo.template == 3">
                                                <div class="w-fullbg-white rounded-sm">
                                                    <div style="display: flex; gap: 16px; align-items: center;">
                                                        <img v-if="fo.image" style="height: 160px; max-width: 75%;" :src="fo.image" alt="">
                                                        <b-button style="height: 40px;" v-b-modal.bv-modal-example-xl @click="getImage(fo)">Select Image</b-button>
                                                    </div>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.caption" type="text" placeholder="Enter image caption"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.title" type="text" placeholder="Enter title"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-textarea v-model="fo.details" id="textarea-default" placeholder="Enter details"></b-form-textarea>
                                                </div>
    
                                            </div>
                                            <div style="display: flex; flex-direction: column; gap: 12px;" class="section-screen" v-if="fo.template == 4">
                                                <div class="w-fullbg-white rounded-sm">
                                                    <div style="display: flex; gap: 16px; align-items: center;">
                                                        <img v-if="fo.image" style="height: 160px; max-width: 75%;" :src="fo.image" alt="">
                                                        <b-button style="height: 40px;" v-b-modal.bv-modal-example-xl @click="getImage(fo)">Select Image</b-button>
                                                    </div>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.caption" type="text" placeholder="Enter image caption"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-input v-model="fo.title" type="text" placeholder="Enter title"></b-form-input>
                                                </div>
                                                <div class="w-fullbg-white rounded-sm">
                                                    <b-form-textarea v-model="fo.details" id="textarea-default" placeholder="Enter details"></b-form-textarea>
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="mt-2">
                                        <b-button style="height: 40px;" @click="onSubmit" variant="primary">Save Template</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-xl-5">
                            <px-card>
                                <div slot="with-padding">
                                    <div style="display: flex; width: 100%; justify-content: center;">
                                        <div>
                                            <div>
                                                <html>
    
                                                <head></head>
                                                <div style="width: 580px;">
                                                    <div id="emailtemplate">
    
                                                        <body>
                                                            <table style="width: 100%;" align="center" width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td> </td>
                                                                        <td class="full-body" style="background-color: #ffffff;">
                                                                            <div>
                                                                               
                                                                                <div style="width: 100%;" v-for="(fo, index) in layouts" :key="index">
    
                                                                                    <div v-if="fo.template == 1">
    
                                                                                        <div>
                                                                                            <div>
                                                                                                <div style="width: 100%; display: flex; align-items: left;">
                                                                                                    <p style="width: 100%; font-size: x-large; font-weight: 600; text-align: left; color: #000000; line-height: 2rem;">{{ fo.title }}</p>
                                                                                                </div>
    
                                                                                                <div style="overflow: hidden; margin-top: 12px;">
                                                                                                    <div style="float: right; margin-left: 16px;">
                                                                                                        <div>
                                                                                                            <img style="width: 360px; margin-bottom: 4px;" :src="fo.image" alt="">
                                                                                                        </div>
                                                                                                        <div style="max-width: 350px; word-wrap: break-word; color: #1E1E1E; text-align: left;">
                                                                                                            <i style="font-size: smaller; margin-top: 2px;">{{ fo.caption }}</i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style="text-align: justify; color: #1E1E1E;">
                                                                                                        <span>{{ fo.details }}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <br />
    
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
    
                                                                                    <div v-else-if="fo.template == 2">
                                                                                        <div>
                                                                                            <div>
                                                                                                <div style="width: 100%; display: flex; align-items: left;">
                                                                                                    <p style="width: 100%; font-size: x-large; font-weight: 600; text-align: left; color: #000000; line-height: 2rem;">{{ fo.title }}</p>
                                                                                                </div>
    
                                                                                                <div style="overflow: hidden; margin-top: 12px;">
                                                                                                    <div style="float: left; margin-right: 16px;">
                                                                                                        <div>
                                                                                                            <img style="width: 360px;margin-bottom: 4px" :src="fo.image" alt="">
                                                                                                            <div style="max-width: 350px; word-wrap: break-word; color: #1E1E1E; text-align: left">
                                                                                                                <i style="font-size: smaller; margin-top: 2px;">{{ fo.caption }}</i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style="text-align: justify; color: #1E1E1E;">
                                                                                                        <span>{{ fo.details }}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <br />
    
                                                                                            </div>
                                                                                        </div>
    
                                                                                    </div>
                                                                                    <div v-else-if="fo.template == 3">
    
                                                                                        <div>
                                                                                            <div>
                                                                                                <div style="width: 100%;">
                                                                                                    <div style="width: 100%;">
                                                                                                        <div style="width: 100%;">
                                                                                                            <p v-if="details.title" style="width: 100%; margin-bottom: 16px; font-size: x-large; font-weight: 600; text-align: left; color: #000000; line-height: 2rem;">{{ fo.title }}</p>
                                                                                                            <div>
                                                                                                                <img style="width: 100%;" :src="fo.image" alt="">
                                                                                                                <div style="max-width: 90%; word-wrap: break-word; color: #1E1E1E; text-align: left;">
                                                                                                                    <i style="font-size: smaller; margin-top: 2px;">{{ fo.caption }}</i>
                                                                                                                </div>
                                                                                                            </div>
    
                                                                                                            <div v-if="fo.details" style="text-align: justify; color: #1E1E1E;">
                                                                                                                <span>{{ fo.details }}</span>
                                                                                                            </div>
                                                                                                            <br />
    
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
    
                                                                                    </div>
                                                                                    <div v-else-if="fo.template == 4">
                                                                                        <div>
                                                                                            <div>
                                                                                                <div style="width: 100%;">
                                                                                                    <div style="width: 100%;">
                                                                                                        <div style="width: 100%;">
                                                                                                            <p v-if="details.title" style="width: 100%; font-size: x-large; font-weight: 600; text-align: left; color: #000000;">{{ fo.title }}</p>
    
                                                                                                            <div v-if="fo.details" style="text-align: justify; margin-top: 12px; color: #1E1E1E;">
                                                                                                                <span>{{ fo.details }}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <br />
                                                                                                        <img style="width: 100%;" :src="fo.image" alt="">
                                                                                                        <div style="max-width: 90%; word-wrap: break-word; color: #1E1E1E; text-align: left;">
                                                                                                            <i style="font-size: smaller; margin-top: 2px;">{{ fo.caption }}</i>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div v-else>
                                                                                        <div>
                                                                                            <div style="padding: 20px;">
                                                                                                <p style="font-weight: 500; font-size: large; text-align: center; padding-top: 20px;">No Preview to Show!</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
    
                                                                            
    
                                                                            </div>
                                                                        </td>
                                                                        <td> </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </body>
                                                    </div>
                                                </div>
    
                                                </html>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </px-card>
    
                        </div>
                    </div>
                </div>
    
               
                <b-modal id="bv-modal-example-xl" size="xl" hide-footer>
                    <template #modal-title>
                        Select
                    </template>
                    <div>
                        <div class="d-block text-left">
                            <div class="full-content">
    
                                <div class="upload-content border-style">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input class="form-control" type="text" placeholder="Enter title" v-model="content.title">
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 100%; display: flex; flex-direction: column; align-items: start;">
                                        <img v-if="demo_image" style="height: 120px; max-width: 100%; margin-bottom: 24px;" :src="demo_image" alt="">
                                        <input type="file" accept="image/*" @change="uploadAttachment">
                                        <b-button style="margin-top: 24px;" variant="primary" @click="submitContent">Upload</b-button>
                                    </div>
                                </div>
                                <div style="width: 100%;">
                                    <div class="task-board-task-scroll">
                                        <div class="image-content border-style" style="height: 100%; width: 100%; overflow-y: auto;">
                                            <div class="content-grid-view">
                                                <div v-for="image, index in image_contents" :key="index">
                                                    <div @click="selectImage(image)" style="height: 160px;">
                                                        <img class="content-size" style="max-height: 160px; object-fit: contain;" :src="image.image" alt="">
                                                    </div>
                                                    <p @click="selectImage(image)" style="margin-top: 6px;">{{ image.title }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
    
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
    </template>
    
        
    <script>
    import {
        mapState
    } from 'vuex';
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import Swal from 'sweetalert2/dist/sweetalert2.js';
    import 'sweetalert2/src/sweetalert2.scss';
    import moment from "moment";
    import Multiselect from 'vue-multiselect';
    import 'sweetalert2/src/sweetalert2.scss';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import treeVue from '../../advance/tree.vue';
    import loader from '../loader/loader.vue'
    import {
        myHost
    } from '../../../_helpers/host'
    export default {
        components: {
            vueDropzone: vue2Dropzone,
            Datepicker,
            loader,
        },
    
        data() {
            return {
                test: true,
                demo_image: null,
                selectedImage: null,
                host: myHost.host,
                isDisabled: false,
                editor: ClassicEditor,
                details: {
                  
                    inner_html: null,
                    value: null,
    
                },
                show_image: null,
                show_image2: null,
                layouts: [{
                    template: null,
                    caption: '',
                    title: '',
                    details: '',
                    image: null
                }],
                options: [{
                        title: 'Select a template',
                        value: 'null',
                        disabled: true
                    },
                    {
                        title: 'Template - 1',
                        value: '1'
                    },
                    {
                        title: 'Template - 2',
                        value: '2'
                    },
                    {
                        title: 'Template - 3',
                        value: '3'
                    },
                    {
                        title: 'Template - 4',
                        value: '4'
                    }
                ],
    
                content: {
                    title: '',
                    type: '',
                    type_slug: '',
                    image: '',
    
                },
    
            };
        },
    
        watch: {
            whereWeWorkDetails(oldValue, newValue) {

    
                if (this.whereWeWorkDetails.value !== null) {
                    this.layouts = this.whereWeWorkDetails.value
                } else {
                    this.layouts = [{
                        template: null,
                        title: '',
                        details: '',
                        image: null
                    }]
                }
    
            },
        },
    
        computed: {
            ...mapState({
                whereWeWorkDetails: state => state.where_we_work.where_we_work_detail,
                loading: state => state.where_we_work.where_we_work_Loading,
                error: state => state.where_we_work.where_we_work_Error,
                image_contents: state => state.content.content_by_type,
            }),
    
        },
    
        mounted() {
    
            this.$store.dispatch("where_we_work/fetchWhereWeWorkDetail")
         
            this.$store.dispatch("content/fetchContentbyType", {
                type: 'where_we_work',
               
            })
    
        },
    
        methods: {
            testToggle() {
                if (this.test) {
                    this.test = false
                } else {
                    this.test = true
                }
            },
            getImage(fo) {
                this.selectedImage = fo
            },
            submitContent() {
                this.content.type = 'where_we_work'
                this.content.type_slug = 'where_we_work',
                
                this.$store.dispatch("content/addContent", {
                    content: this.content,
                    type:'where_we_work',
                }).then(response => {
    
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.content.title = ''
                        this.content.type = null,
                        this.content.type_slug = null
                        this.content.image = null
                        this.demo_image = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
    
            },
            selectImage(img) {
                this.testToggle()
                this.selectedImage.image = img.image
                this.$nextTick(() => {
                    this.$bvModal.hide('bv-modal-example-xl')
                })
                this.testToggle()
    
            },
    
            addField(value, fieldType) {
                fieldType.push({
                    value: ""
                });
            },
    
            removeField(index, fieldType) {
                fieldType.splice(index, 1);
            },
    
            uploadAttachment(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.content.image = e.target.result;
                    this.demo_image = e.target.result;
                };
            },
    
            onSubmit() {
                this.isDisabled = true
                this.details.inner_html = document.getElementById('emailtemplate').innerHTML;
                this.details.value = this.layouts
                this.$store.dispatch("where_we_work/addWhereWeWork", {
                    where_we_work: this.details,
                   
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
                        this.isDisabled = false,
                            this.details.inner_html = null
                            this.details.value = null
                       
    
                    } else {
                        this.isDisabled = false
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
    
                })
            }
        }
    };
    </script>
    
        
    <style scoped>
    .task-board-task-scroll {
        position: relative;
        height: 87.8vh;
    }
    
    /* height */
    ::-webkit-scrollbar {
        height: 6px;
        width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    
    .main-div-layout {
        width: 100%;
        display: flex;
        gap: 24px;
    }
    
    .big-screen-width {
        width: 70%;
    }
    
    .section-screen {
        width: 90%;
    }
    
    .main-template-layout {
        width: 60%;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 40px;
    }
    
    .main-demo-layout {
        width: 40%;
    }
    
    .content-container {
        overflow: hidden;
        /* Clearfix for floats */
    }
    
    .image-container4 {
        float: left;
        margin-right: 16px;
    }
    
    .content-grid-view {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    
    .content-size {
        width: 230px;
    }
    
    .imageStyle {
        background-size: cover;
        object-fit: cover;
        width: 100%;
    }
    
    .full-content {
        width: 100%;
        display: flex;
        gap: 16px;
    }
    
    .border-style {
        border: 1px solid;
        padding: 16px;
        border-radius: 5px;
        border-color: rgb(211, 211, 211);
    }
    
    .upload-content {
        width: 30%;
    }
    
    .image-content {
        width: 70%;
    }
    
    .filter-section {
        display: flex;
        gap: 12px;
    }
    
    .box-width {
        width: 250px;
    }
    
    @media screen and (max-width: 1470px) {
        .content-grid-view {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 24px;
        }
    
        .content-size {
            width: 270px;
        }
    
        .main-div-layout {
            gap: 0px;
        }
    
        .main-template-layout {
            width: 50%;
        }
    
        .main-demo-layout {
            width: 50%;
        }
    }
    
    @media screen and (max-width: 1230px) {
        .content-size {
            width: 230px;
        }
    }
    
    @media screen and (max-width: 1199px) {
        .filter-section {
            display: flex;
            flex-direction: column;
            gap: 0px;
        }
    
        .content-grid-view {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 24px;
        }
    
        .content-size {
            width: 210px;
        }
    
        .upload-content {
            width: 50%;
        }
    
        .image-content {
            width: 50%;
        }
    }
    
    @media screen and (max-width: 991px) {
        .full-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 32px;
        }
    
        .upload-content {
            width: 100%;
        }
    
        .image-content {
            width: 100%;
        }
    
        .content-grid-view {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 24px;
        }
    
        .content-size {
            width: 200px;
        }
    
        .main-div-layout {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    
        .big-screen-width {
            width: 100%;
        }
    
        .section-screen {
            width: 100%;
        }
    
        .main-template-layout {
            width: 100%;
        }
    
        .main-demo-layout {
            width: 100%;
        }
    }
    
    @media screen and (max-width: 835px) {
        .content-grid-view {
            display: grid;
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 24px;
        }
    
        .content-size {
            width: 250px;
        }
    }
    
    @media screen and (max-width: 645px) {
        .box-width {
            width: 100%;
        }
    }
    
    @media screen and (max-width: 467px) {
        .filter-section {
            display: flex;
            flex-direction: column;
            gap: 0px;
        }
    }
    
    @media screen and (max-width: 430px) {
        .content-size {
            width: 100%;
        }
    }
    </style>
    