import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'GALLERY_UPDATE',
    smsDelete : 'GALLERY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        fellowship:[],
        fellowship_detail:{},
       
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        fellowshipLoading: false,
        fellowshipError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_FELLOWSHIP(state, fellow) {
            state.fellowship = fellow;
        },
        SET_FELLOWSHIP_DETAIL(state, detail) {
            state.fellowship_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        FELLOWSHIP_LOADING (state, loading) {
            state.fellowshipLoading = loading;
        },

        FELLOWSHIP_ERROR (state, hasError) {
            state.fellowshipError = hasError;
        },
        


       
    },

    actions: {
        
        async addFellowship ({commit, dispatch}, payload) {
            commit('FELLOWSHIP_LOADING', true);
            commit('FELLOWSHIP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.fellowship_create, payload.fellowship, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
               
               
                return response

            }catch (e) {
                commit('FELLOWSHIP_ERROR', true);
            }finally {
                commit('FELLOWSHIP_LOADING', false);
            }
        },

        async fetchFellowshipDetail ({ commit },payload) {
            commit('FELLOWSHIP_LOADING', true);
            commit('FELLOWSHIP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.fellowship_details + payload, config);
                let response = result.data.data

                commit('SET_FELLOWSHIP_DETAIL', response);

            } catch(error) {
                commit('FELLOWSHIP_ERROR', true);
            }
            finally {
                commit('FELLOWSHIP_LOADING', false);
            }
        },

       


        async fetchFellowshipList ({ commit }) {
            commit('FELLOWSHIP_LOADING', true);
            commit('FELLOWSHIP_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.fellowship_list, config);
                let response = result.data

                commit('SET_FELLOWSHIP', response.data);

            } catch(error) {
                commit('FELLOWSHIP_ERROR', true);
            }
            finally {
                commit('FELLOWSHIP_LOADING', false);
            }
        },


    
        async updateFellowship ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.fellowship_edit+payload.fellowship_id, payload.fellowship, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
              
                return response

            }catch (e) {
                commit('FELLOWSHIP_ERROR', true);
            }finally {
                commit('FELLOWSHIP_LOADING', false);
            }
        },

        
        async deleteFellowship({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
          
            let response = await axios.delete(V1API.fellowship_delete+payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchFellowshipList')
            
            return response
        },


        async updateStatusToggle({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
            console.log(payload.delete)
            let response = await axios.get(V1API.fellowship_status_toggle+payload.fellowship_id, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchFellowshipList')
            
            return response
        },


        











       








    },

    getters: {}
}
