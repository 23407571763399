import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'WHERE_WE_WORK_UPDATE',
    smsDelete : 'WHERE_WE_WORK_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        

      
        where_we_work_detail:{},

        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        where_we_work_Loading: false,
        where_we_work_Error: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,


        SET_WHERE_WE_WORK_DETAIL(state, detail) {
            state.where_we_work_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        WHERE_WE_WORK_LOADING (state, loading) {
            state.where_we_work_Loading = loading;
        },

        WHERE_WE_WORK_ERROR (state, hasError) {
            state.where_we_work_Error = hasError;
        },
        


       
    },

    actions: {

        //Where We Wrok
        async addWhereWeWork({commit, dispatch}, payload) {
            commit('WHERE_WE_WORK_LOADING', true);
            commit('WHERE_WE_WORK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.where_we_work_create, payload.where_we_work, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchWhereWeDoDetail')
               
                return response

            }catch (e) {
                commit('WHERE_WE_WORK_ERROR', true);
            }finally {
                commit('WHERE_WE_WORK_LOADING', false);
            }
        },

        async fetchWhereWeWorkDetail({ commit },payload) {
            commit('WHERE_WE_WORK_LOADING', true);
            commit('WHERE_WE_WORK_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.where_we_work_detail, config);
                let response = result.data.data

                commit('SET_WHERE_WE_WORK_DETAIL', response);

            } catch(error) {
                commit('WHERE_WE_WORK_ERROR', true);
            }
            finally {
                commit('WHERE_WE_WORK_LOADING', false);
            }
        },


    },

    getters: {}
}
