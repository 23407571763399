import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'INFOGRAPHY_UPDATE',
    smsDelete : 'INFOGRAPHY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        
        infoGraphy:[],
        infoGraphy_detail:{},
       
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        infoGraphyLoading: false,
        infoGraphyError: false,

        
       
        ...store
    },

    mutations : {
        ...mutations,

        SET_INFO_GRAPHY(state, ig) {
            state.infoGraphy = ig;
        },
        SET_INFO_GRAPHY_DETAIL(state, detail) {
            state.infoGraphy_detail = detail;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },


        INFO_GRAPHY_LOADING (state, loading) {
            state.infoGraphyLoading = loading;
        },

        INFO_GRAPHY_ERROR (state, hasError) {
            state.infoGraphyError = hasError;
        },
        


       
    },

    actions: {
        //Audio Visuals
        async addInfoGraphy ({commit, dispatch}, payload) {
            commit('INFO_GRAPHY_LOADING', true);
            commit('INFO_GRAPHY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.infography_create, payload.infography, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchInfoGraphyList')
               
               
                return response

            }catch (e) {
                commit('INFO_GRAPHY_ERROR', true);
            }finally {
                commit('INFO_GRAPHY_LOADING', false);
            }
        },

        async fetchInfoGraphyDetail ({ commit },payload) {
            commit('INFO_GRAPHY_LOADING', true);
            commit('INFO_GRAPHY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.infography_details + payload, config);
                let response = result.data.data

                commit('SET_INFO_GRAPHY_DETAIL', response);
                
                

            } catch(error) {
                commit('INFO_GRAPHY_ERROR', true);
            }
            finally {
                commit('INFO_GRAPHY_LOADING', false);
            }
        },

       


        async fetchInfoGraphyList ({ commit }) {
            commit('INFO_GRAPHY_LOADING', true);
            commit('INFO_GRAPHY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.infography_list, config);
                let response = result.data

                commit('SET_INFO_GRAPHY', response.data);

            } catch(error) {
                commit('INFO_GRAPHY_ERROR', true);
            }
            finally {
                commit('INFO_GRAPHY_LOADING', false);
            }
        },


    
        async updateInfoGraphy ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.patch(V1API.infography_edit+payload.infography_id, payload.infography, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchInfoGraphyList')
              
                return response

            }catch (e) {
                commit('INFO_GRAPHY_ERROR', true);
            }finally {
                commit('INFO_GRAPHY_LOADING', false);
            }
        },

        
        async deleteInfoGraphy({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };
          
            let response = await axios.delete(V1API.infography_delete+payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch ('fetchInfoGraphyList')
            
            return response
        },


    

    },

    getters: {}
}
