<template>
<div>
    <Breadcrumbs main="Admin" :submain="this.$route.params.slug" title="Base Details" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" v-if="!loading && !error">
                <px-card>
                    <div slot="with-padding">
                        <div class="form theme-form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Title</label>
                                        <input class="form-control" type="text" placeholder="Enter Title" v-model="details.title">
                                    </div>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Counter</label>
                                        <input class="form-control" type="number" min="1" placeholder="Enter Counter" v-model="details.counter">
                                    </div>
                                </div> -->
                            </div>

                            <div class="row">
                                <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Upload Banner</label>
                                            <div class="flex items-center">
                                                <img v-if="show_banner" style="height: 120px;" :src="show_banner">
                                                <input :class="details.banner ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadFile">
                                            </div>
                                        </div>
                                    </div> -->
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Upload Image (1:1 Ratio)</label>
                                        <div class="flex items-center">
                                            <img v-if="show_image" style="height: 120px;" :src="show_image">
                                            <input :class="details.image ? 'ml-4' : 'ml-4'" type="file" accept="image/*" @change="uploadImage">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Short Description</label>
                                        <input class="form-control" type="text" placeholder="Enter short details" v-model="details.short">
                                        <!-- <b-form-textarea id="textarea-auto-height" placeholder="Enter organization details..." v-model="details.short" rows="2" max-rows="4"></b-form-textarea> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <ckeditor :editor="editor" placeholder="Enter description..." v-model="details.description"></ckeditor>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-0">
                                        <button v-if="setting == null" @click="onSubmit()" class="btn btn-success mr-3">Create</button>
                                        <button v-else @click="onSubmit()" class="btn btn-primary mr-3">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </px-card>
            </div>

            <div class="col-sm-12" v-else>
                <px-card>
                    <loader />
                </px-card>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    myHost
} from '../../../_helpers/host'
import {
    mapState
} from 'vuex';
import loader from '../loader/loader.vue'
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        loader
    },

    data() {
        return {
            host: myHost.host,
            editor: ClassicEditor,
            details: {
                title: null,
                counter: '',
                image: null,
                banner: null,
                type: '',
                description: '',
                short: '',

            },
            show_banner: null,
            show_image: null,

        };
    },

    watch: {
        setting(oldValue, newValue) {
            this.details.title = this.setting.title ? this.setting.title : null
            this.details.type = this.setting.type ? this.setting.type : null
            this.details.counter = this.setting.counter ? this.setting.counter : null
            this.details.description = this.setting.description ? this.setting.description : null
            this.details.short = this.setting.short ? this.setting.short : null
            this.show_image = this.setting.image ? this.host + this.setting.image : null
            this.show_banner = this.setting.banner ? this.host + this.setting.banner : null

        },
        '$route.params.slug'(newSlug, oldSlug) {
            if (newSlug !== oldSlug) {
                this.$store.dispatch("baseDetail/fetchBaseDetail", newSlug);
            }

        },
    },

        mounted() {
            this.$store.dispatch("baseDetail/fetchBaseDetail", this.$route.params.slug)
        },

        computed: {
            ...mapState({
                setting: state => state.baseDetail.base_detail,
                loading: state => state.baseDetail.baseLoading,
                error: state => state.baseDetail.baseError,
            })

        },
        methods: {
            truncateText(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                } else {
                    return text;
                }
            },

            uploadFile(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.show_banner = e.target.result
                    this.details.banner = e.target.result;
                };
            },

            uploadImage(e) {
                const image = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.show_image = e.target.result
                    this.details.image = e.target.result;

                };
            },

            onSubmit() {
                this.details.type = this.$route.params.slug
                this.$store.dispatch("baseDetail/addBaseDetail", {
                    type: this.$route.params.slug,
                    base_detail: this.details
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });

                            // this.details.title = '',
                            // this.details.type = '',
                            // this.details.counter = '',
                            // this.details.image = null,
                            // this.details.banner = null,
                            // this.details.description = '',
                            // this.details.short = ''

                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            },

        }
    };
</script>
