
let final_list={
    "data":[
        // {
        //     "path": "/dashboard",
        //     "title": "Dashboard",
        //     "icon": "home",
        //     "type": "link",
        //     "active": false
        // },
        {
            "headTitle1": "Home",
            "headTitle2": "Manage Admin Contents",
            "type": "headtitle"
        },
        {
            "path": "/admin/slider",
            "title": "Slider",
            "icon": "disc",
            "type": "link"
        },
       
        {
            "path": "/admin/counter",
            "title": "Counter",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/team",
            "title": "Team Member",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/partner",
            "title": "Partners",
            "icon": "disc",
            "type": "link"
        },

        {
            "path": "/admin/application",
            "title": "Application",
            "icon": "disc",
            "type": "link"
        },
       

     
       
 


        //Resources

        {
            "headTitle1": "Resources",
            "headTitle2": "Manage Resources",
            "type": "headtitle"
        },
        {
            "title": "Press Release",
            "type": "sub",
            "icon": "disc",
            "active": false,
            "children": [
                {
                    "path": "/admin/press-release",
                    "title": "Press Release",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/quotes",
                    "title": "Quotes",
                    "icon": "disc",
                    "type": "link"
                },

            ]
        },
        {
            "path": "/admin/news",
            "title": "News",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/law-policy",
            "title": "Law & Policy",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/audio-visuals",
            "title": "Audio Visuals",
            "icon": "disc",
            "type": "link"
        },

        {
            "path": "/admin/info-graphics",
            "title": "Info Graphics",
            "icon": "disc",
            "type": "link"
        },
       




     
        //Get Involved
        {
            "headTitle1": "Get Involved",
            "headTitle2": "Manage Involvements",
            "type": "headtitle"
        },
        {
            "path": "/admin/jobs",
            "title": "Employment",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/cfp",
            "title": "Call for Proposal",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/fellowship",
            "title": "Fellowship",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/qna",
            "title": "FAQ",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/contact",
            "title": "Contact",
            "icon": "disc",
            "type": "link"
        },

        

        

        {
            "headTitle1": "Campaigns & Gallery",
            "headTitle2": "Manage Campaigns and Gallery",
            "type": "headtitle"
        },
        {
            "path": "/admin/gallery",
            "title": "Gallery",
            "icon": "disc",
            "type": "link"
        },

        {
            "path": "/admin/campaign",
            "title": "Campaigns",
            "icon": "disc",
            "type": "link"
        },
    


       


        {
            "title": "Base Details",
            "type": "sub",
            "icon": "disc",
            "active": false,
            "children": [
                {
                    "path": "/admin/base-detail/campaign",
                    "title": "Campaign",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/law_n_policy",
                    "title": "Law & Policy",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/news",
                    "title": "News",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/press_release",
                    "title": "Press Release",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/mission_vision",
                    "title": "Mission & Vision",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/accountibility",
                    "title": "Accountibility",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/governance",
                    "title": "Governance",
                    "icon": "disc",
                    "type": "link"
                },
                {
                    "path": "/admin/base-detail/about_article19_asia",
                    "title": "About Article 19 Asia",
                    "icon": "disc",
                    "type": "link"
                },
               
               
              
            ]
        },

    

 

      


        {
            "headTitle1": "About Us",
            "headTitle2": "Manage about us",
            "type": "headtitle"
        },
       
        {
            "path": "/admin/base-detail/who_we_are",
            "title": "Who We Are",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/what-we-do",
            "title": "What We Do",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/where-we-work",
            "title": "Where We Work",
            "icon": "disc",
            "type": "link"
        },


        

        {
            "headTitle1": "Settings & More",
            "headTitle2": "Manage settings & more",
            "type": "headtitle"
        },
        {
            "path": "/admin/site-setting",
            "title": "Site Settings",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/content",
            "title": "Content",
            "icon": "disc",
            "type": "link"
        },
       
       
        {
            "path": "/admin/subscribers",
            "title": "Subscribers",
            "icon": "disc",
            "type": "link"
        },
        {
            "path": "/admin/annual-reports",
            "title": "Annual Reports",
            "icon": "disc",
            "type": "link"
        },

       


        

      
       

      

        
       
       

       
       
       
      
       
     
      
       
    ]
}






export default final_list
